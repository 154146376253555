import React, { useEffect, useCallback } from 'react';
import Card from '../../components/Card';
import List from '../../components/List';
import { useMachine } from '@xstate/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
    faUser,
    faArrowLeft,
    faDownload,
} from '@fortawesome/free-solid-svg-icons';
import ListItem from '../../components/ListItem';
import { gameMachine } from '../../xstate/games/game.machine';
import { useParams, Link } from 'react-router-dom';
import DrawnNumbersCard from '../../components/DrawnNumbersCard';
import RegisterNumberCard from '../../components/RegisterNumberCard';
import CheckWinnerCard from '../../components/CheckWinnerCard';
import CardMenu from '../../components/CardMenu';
import { CardClaim } from '../../models/card-claim.model';
import { GameType } from '../../types/game-type.enum';

export default function () {
    const [currentGame, gameSend] = useMachine(gameMachine);
    const { gameId } = useParams<{ gameId: string }>();

    // When we get the game id then fetch the data;
    useEffect(() => {
        gameSend({ type: 'FETCH', gameId: Number(gameId) });
    }, [gameSend, gameId]);

    const { data: gameData } = currentGame.context;

    const roundNumber =
        currentGame.context.activeRound?.roundNumber ?? 'Indlæser...';
    const cardCountText = gameData?.cards?.length ?? 'Indlæser...';
    const aggregateCardClaims = useCallback(() => {
        const cardClaims = currentGame.context.data?.cards?.reduce<CardClaim[]>(
            (acc: CardClaim[], { cardClaim }) => {
                if (acc.find(({ id }) => id === cardClaim?.id)) {
                    return acc;
                }

                return [...acc, cardClaim!];
            },
            []
        );

        return cardClaims ?? [];
    }, [currentGame.context.data]);

    const cardClaims = aggregateCardClaims();

    const cardClaimCountText = currentGame.matches('complete')
        ? cardClaims.length
        : 'Indlæser...';

    const invitationLink = `${window.location.origin}/claim/${gameId}`;

    const gameTypeMap = {
        [GameType.Physical]: 'Fysisk',
        [GameType.Virtual]: 'Virtuel',
    };

    return (
        <div className="page page-home">
            <div className="page__header">
                <h1>
                    Bingo Generator{' '}
                    <small style={{ fontSize: '0.4em' }}>
                        {' '}
                        - &copy; Asger Nohns 2020
                    </small>
                </h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Card>
                        <Link to="/dashboard">
                            <FontAwesomeIcon icon={faArrowLeft} /> Tilbage til
                            forsiden
                        </Link>
                    </Card>
                    <Card
                        title="Bankospil"
                        subtitle={`Navn: ${
                            currentGame.context.data?.name ?? 'Indlæser...'
                        }`}
                    >
                        <CardMenu>
                            {currentGame.matches('fetching') && (
                                <p className="list__empty">
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin={true}
                                    />{' '}
                                    {!currentGame.context.data
                                        ? 'Indlæser'
                                        : 'Opdaterer'}{' '}
                                    spil...
                                </p>
                            )}
                        </CardMenu>
                        <p>
                            <strong>Type:</strong>{' '}
                            {gameData &&
                                (gameTypeMap[gameData!.gameType] ?? (
                                    <i>Ukendt</i>
                                ))}
                            <br />
                            <strong>Runde nr.:</strong> {roundNumber}
                            <br />
                            <strong>Antal plader:</strong> {cardCountText}
                            <br />
                            {gameData?.gameType === GameType.Virtual ? (
                                <>
                                    <strong>Antal deltagere:</strong>{' '}
                                    {cardClaimCountText}
                                </>
                            ) : (
                                ''
                            )}
                        </p>
                    </Card>

                    {gameData?.gameType === GameType.Virtual ? (
                        <Card
                            title="Deltagere"
                            subtitle="Brugere der via invitationslinket har genereret bankoplader."
                        >
                            <div
                                style={{ maxHeight: 300, overflowY: 'scroll' }}
                            >
                                <List>
                                    {currentGame.matches('fetching') && (
                                        <p className="list__empty">
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                spin={true}
                                            />{' '}
                                            Indlæser deltagere...
                                        </p>
                                    )}
                                    {currentGame.matches('complete') &&
                                        cardClaims.length > 0 &&
                                        cardClaims.map(
                                            (
                                                { username, cards, email },
                                                index
                                            ) => (
                                                <ListItem
                                                    key={index}
                                                    faIcon={faUser}
                                                    title={
                                                        <div className="card-claim-list__item__title">
                                                            <div className="card-claim-list__item__title__username">
                                                                {username}
                                                            </div>
                                                            <div className="card-claim-list__item__title__email">
                                                                {email}
                                                            </div>
                                                        </div>
                                                    }
                                                    prefix={`${
                                                        cards?.length ?? '-'
                                                    } plader`}
                                                    prefixProps={{
                                                        style: { minWidth: 80 },
                                                    }}
                                                />
                                            )
                                        )}
                                    {currentGame.matches('complete') &&
                                        cardClaims.length === 0 && (
                                            <p className="list__empty">
                                                Ingen deltagere fundet
                                            </p>
                                        )}
                                </List>
                            </div>
                        </Card>
                    ) : (
                        <Card title="Generer plader">
                            <a
                                href={invitationLink}
                                className="btn btn-primary btn-block"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faDownload} />
                                Klik her
                            </a>
                        </Card>
                    )}
                </div>
                <div className="col-md-4">
                    {gameData?.gameType === GameType.Virtual ? (
                        <Card title="Invitationslink">
                            <a
                                target="blank"
                                href={invitationLink}
                                className="text-underline"
                            >
                                {invitationLink}
                            </a>
                        </Card>
                    ) : (
                        ''
                    )}
                    <DrawnNumbersCard
                        currentGame={currentGame}
                        sendGame={gameSend}
                    />
                </div>
                <div className="col-md-4">
                    <RegisterNumberCard
                        gameId={Number(gameId)}
                        onRegister={(number) =>
                            gameSend({
                                type: 'ADD_ACTIVE_DRAWN_NUMBER',
                                number,
                            })
                        }
                        onRegistered={() => gameSend({ type: 'RETRY_FETCH' })}
                        drawnNumbers={currentGame.context.activeDrawnNumbers}
                    />
                    <CheckWinnerCard gameId={Number(gameId)} />
                </div>
            </div>
        </div>
    );
}
