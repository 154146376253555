import React, { FormEvent, useState } from 'react';
import { Modal } from './Modal';
import ModalButtons from './ModalButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GameType } from '../../types/game-type.enum';

export interface CreateGameModalProps {
    isOpen: boolean;
    onClose: CallableFunction;
    onSubmit: (gameName: string, gameType: GameType) => any;
}

export default function ({ isOpen, onClose, onSubmit }: CreateGameModalProps) {
    const [gameName, setGameName] = useState('');
    const [gameType, setGameType] = useState<string | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (
            gameName.trim() === '' ||
            (gameType !== GameType.Physical && gameType !== GameType.Virtual)
        ) {
            alert('Udfyld venligst formularen korrekt.');
            return false;
        }

        // Call on submit handler
        onSubmit(gameName, gameType);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            title="Opret bankospil"
        >
            <form method="post" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Navn</label>
                    <input
                        type="text"
                        className="form-control"
                        value={gameName}
                        onChange={(e) => setGameName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Spiltype</label>
                    <select
                        className="form-control"
                        onChange={(e) => setGameType(e.target.value)}
                    >
                        <option>Vælg en spiltype</option>
                        <option value={GameType.Virtual}>Virtuel</option>
                        <option value={GameType.Physical}>Fysisk</option>
                    </select>
                </div>
                <ModalButtons>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => onClose()}
                    >
                        <FontAwesomeIcon icon={faTimes} /> Annuller
                    </button>
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={faCheck} /> Opret
                    </button>
                </ModalButtons>
            </form>
        </Modal>
    );
}
