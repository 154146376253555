import React, { useState, ChangeEvent, FormEvent } from "react";
import Card from "./Card";
import ModalButtons from "./modals/ModalButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationCircle,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { checkWinner } from "../api";
import CheckWinnerModal from "./modals/CheckWinnerModal";
import { CardWinnerData } from "../models/card-winner-data.model";

export interface CheckWinnerCardProps {
    gameId: number;
}

export default function ({ gameId }: CheckWinnerCardProps) {
    const [winnerData, setWinnerData] = useState<CardWinnerData | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (error) {
            return;
        }

        // Check number
        try {
            const subject = Number(cardNumber);
            const winnerData = await checkWinner(gameId, subject);
            setWinnerData(winnerData);
            console.log("Winner has been set");
            setIsModalOpen(true);
            
        } catch (err) {
            console.log({ err });
            setError('Der opstod en fejl');
        }
    };

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!Number(e.target.value)) {
            setError("Indtast kun gyldige tal");
            setCardNumber(e.target.value);
            return;
        }

        setCardNumber(e.target.value);
        setError(null);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    return (
        <Card title="Tjek plade" subtitle="Indtast pladenummeret nedenfor.">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Fx. 1, 2 eller 65"
                        value={cardNumber}
                        onChange={handleNumberChange}
                    />
                </div>

                {error && (
                    <p className="form__error">
                        <FontAwesomeIcon icon={faExclamationCircle} /> {error}
                    </p>
                )}

                <ModalButtons>
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} /> Tjek plade
                    </button>
                    <CheckWinnerModal isOpen={isModalOpen} onClose={handleModalClose} winnerData={winnerData} />
                </ModalButtons>
            </form>
            
        </Card>
    );
}
