import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';
import {
    faArrowRight,
    faPlus,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMachine } from '@xstate/react';
import { gamesMachine } from '../../xstate/games/games.machine';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import CardMenu from '../../components/CardMenu';
import CreateGameModal from '../../components/modals/CreateGameModal';
import { createGame } from '../../api';
import { GameType } from '../../types/game-type.enum';

export default function () {
    const [current, send] = useMachine(gamesMachine);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newGameName, setNewGameName] = useState<string | null>();
    const [newGameType, setNewGameType] = useState<GameType | null>();

    useEffect(() => {
        if (!newGameName) {
            return;
        }

        if (!newGameType) {
            return;
        }

        // Create bingo game
        createGame(newGameName, newGameType).then((game) =>
            send({ type: 'ADD_GAME', game })
        );
    }, [newGameName, newGameType, send]);

    // Handle a submit from the create game modal
    const handleNewGameSubmit = (name: string, gameType: GameType) => {
        setIsCreateModalOpen(false);
        setNewGameName(name);
        setNewGameType(gameType);
    };

    return (
        <div className="page page-home">
            <div className="page__header">
                <h1>
                    Bingo Generator{' '}
                    <small style={{ fontSize: '0.4em' }}>
                        {' '}
                        - &copy; Asger Nohns 2020
                    </small>
                </h1>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Card title="Bankospil" subtitle="Bankospil i gang pt.">
                        <CardMenu>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => setIsCreateModalOpen(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Opret
                            </button>
                        </CardMenu>
                        <List>
                            {current.matches('fetching') && (
                                <p className="list__empty">
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin={true}
                                    />{' '}
                                    Indlæser bankospil...
                                </p>
                            )}
                            {current.matches('complete') &&
                                current.context.data.length > 0 &&
                                current.context.data.map((game) => (
                                    <ListItem
                                        faIcon={faArrowRight}
                                        title={game.name}
                                        link={`/game/${game.id}`}
                                        prefix="Gå til"
                                    />
                                ))}
                            {current.matches('complete') &&
                                current.context.data.length === 0 && (
                                    <p className="list__empty">
                                        Ingen spil fundet
                                    </p>
                                )}
                        </List>
                    </Card>
                </div>
            </div>
            <CreateGameModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onSubmit={(gameName, gameType) =>
                    handleNewGameSubmit(gameName, gameType)
                }
            />
        </div>
    );
}
