import React from "react";
import Card from "../../components/Card";

export default function () {
    return (
        <div className="page page-home">
            <div className="page__header">
                <h1>
                    Bingo Generator{" "}
                    <small style={{ fontSize: "0.4em" }}>
                        {" "}
                        - &copy; Asger Nohns 2020
                    </small>
                </h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Card
                        title="Siden blev ikke fundet"
                        subtitle="Siden du efterspurgte blev ikke fundt!"
                    ></Card>
                </div>
            </div>
        </div>
    );
}
