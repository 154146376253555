import React from 'react';
import { Modal } from "./Modal";
import ModalButtons from "./ModalButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface ConfirmClearNumbersModalProps {
    isOpen: boolean;
    onClose: CallableFunction;
    onConfirm: CallableFunction;
}

export default function({ isOpen, onClose, onConfirm }: ConfirmClearNumbersModalProps) {
    return (
        <Modal title="Ryd pladen" isOpen={isOpen} onClose={onClose} >
            <p>Er du sikker på du vil rydde pladen, og starte en ny runde?</p>
            <ModalButtons>
                <button type="button" className="btn btn-light" onClick={() => onClose()}><FontAwesomeIcon icon={faTimes} /> Annuller</button>
                <button type="button" className="btn btn-danger" onClick={() => onConfirm()}><FontAwesomeIcon icon={faTrash} /> Ryd</button>
            </ModalButtons>
        </Modal>
    )
}