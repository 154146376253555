import React, { ReactNode } from "react";

export interface ModalStatusProps {
    children: ReactNode,
}

export default function({ children }: ModalStatusProps) {
    return (
        <div className="nohns-modal__status">{children}</div>
    )
}