
import { Machine, MachineConfig, MachineOptions, assign, DoneInvokeEvent } from 'xstate';
import { API_HOST } from '../config.constants';
import { FetchEvent, CardClaimMachineContext, CardClaimMachineStateSchema, CardClaimMachineEvent } from './card-claim.schema';
import { CardClaim } from '../../models/card-claim.model';

const configuration: MachineConfig<CardClaimMachineContext, CardClaimMachineStateSchema, CardClaimMachineEvent> = {
    id: 'cardClaim',
    initial: 'idle',
    states: {
        idle: {
            on: {
                FETCH: {
                    target: 'fetching',
                    actions: 'setIds',   
                }
            }
        },
        fetching: {
            invoke: {
                id: 'cardClaimFetch',
                src: 'cardClaimFetcher',
                onDone: {
                    target: 'complete',
                    actions: 'setData',
                },
                onError: {
                    target: 'failed',
                    actions: 'setError',
                },
            },
        },
        complete: {
            on: {
            }
        },
        failed: {
            on: {
                RETRY_FETCH: 'fetching',
            }
        }
    }
}

const options: MachineOptions<CardClaimMachineContext, CardClaimMachineEvent> = {
    services: {
        cardClaimFetcher: (ctx: CardClaimMachineContext) => cardClaimFetcherService(ctx.gameId!, ctx.cardClaimId!),
    },
    guards: {},
    actions: {
        setIds: assign<CardClaimMachineContext, any>({
            gameId: (_ctx: any, event: FetchEvent) => event.gameId,
            cardClaimId: (_ctx: any, event: FetchEvent) => event.cardClaimId,
        }),
        setData: assign<CardClaimMachineContext, any>({
            data: (_ctx: any, event: DoneInvokeEvent<CardClaim>) => { console.log(event); return event.data}
        }),
        setError: assign<CardClaimMachineContext, any>({ 
            error: (_ctx: any, event: any) => event.data 
        }),
    },
    activities: {},
    delays: {},
}

const cardClaimFetcherService = async (gameId: number, cardClaimId: number) => {
    return fetch(`${API_HOST}/card-claims/${gameId}/${cardClaimId}`)
        .then(res => res.json())
        .then<CardClaim>(json => json.data);
}

export const cardClaimMachine = Machine(configuration, options);