import * as React from "react";
import * as ReactDOM from "react-dom";
import "./modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "react-spring/renderprops";

export type ModalProps = {
    title: string;
    isOpen: boolean;
    onClose: CallableFunction;
    children: React.ReactNode;
};
export type ModalState = any;

export class Modal extends React.Component<ModalProps, ModalState> {
    private container: HTMLDivElement;
    private modalRoot: HTMLElement;

    public constructor(props: ModalProps) {
        super(props);

        this.modalRoot =
            document.getElementById("root-modal") ||
            document.createElement("div");
        this.modalRoot.className = "root-modal";
        this.container = document.createElement("div");
        this.container.classList.add("nohns-modal__wrapper");
    }

    public componentDidMount(): void {
        this.modalRoot.appendChild(this.container);
    }

    public componentWillUnmount(): void {
        this.modalRoot.removeChild(this.container);
    }

    public render(): React.ReactElement<ModalProps> {
        // Update modal wrapper class
        if (this.container.classList.contains("open") !== this.props.isOpen) {
            this.container.classList.toggle("open");
        }

        return ReactDOM.createPortal(
            <>
                <div
                    className={`nohns-modal__overlay${
                        this.props.isOpen ? " open" : ""
                    }`}
                ></div>

                <Transition
                  config={{tension: 150, mass: 1, friction: 15}}
                    items={this.props.isOpen}
                    from={{ position: 'absolute', opacity: 0, transform: "translateY(400%)" }}
                    enter={{ opacity: 1, transform: "translateY(0)" }}
                    leave={{ opacity: 0, transform: "translateY(400%)" }}
                >
                    {(show) =>
                        show &&
                        ((props) => (
                            <div
                                className={`nohns-modal${
                                    this.props.isOpen ? " open" : ""
                                }`}
                                style={props}
                            >
                                <div className="nohns-modal__close">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={() => this.props.onClose()}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                                <div className="nohns-modal__header">
                                    <h4>{this.props.title}</h4>
                                </div>
                                <div className="nohns-modal__body">
                                    {this.props.children}
                                </div>
                            </div>
                        ))
                    }
                </Transition>
            </>,
            this.container
        );
    }
}
