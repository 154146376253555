import React, { useEffect, useState, FormEvent } from 'react';
import { useMachine } from '@xstate/react';
import { gameMachine } from '../../xstate/games/game.machine';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import ModalButtons from '../../components/modals/ModalButtons';
import ModalStatus from '../../components/modals/ModalStatus';
import { claimCards, APIError } from '../../api';
import { CardClaim } from '../../models/card-claim.model';
import { GameType } from '../../types/game-type.enum';

const MAX_CARD_AMOUNT = 3;

export default function () {
    const [cardAmount, setCardAmount] = useState<string>('--');
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [cardClaim, setCardClaim] = useState<CardClaim | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [currentGame, gameSend] = useMachine(gameMachine);
    const { gameId } = useParams<{ gameId: string }>();

    // When we get the game id then fetch the data;
    useEffect(() => {
        gameSend({ type: 'FETCH', gameId: Number(gameId) });
    }, [gameSend, gameId]);

    const { data: game } = currentGame.context;
    const isPhysicalGame = game?.gameType === GameType.Physical;

    const handleCardClaim = async (e: FormEvent) => {
        e.preventDefault();
        setError(null);

        // Validate input
        const numberCardAmount = Number(cardAmount);
        if (!numberCardAmount || numberCardAmount % 1 !== 0) {
            setError('Indtast gyldigt tal i antal plader.');
            return;
        }

        if (
            !isPhysicalGame &&
            (numberCardAmount > MAX_CARD_AMOUNT || numberCardAmount < 1)
        ) {
            setError(
                `Antal plader skal et tal mellem 1 og ${MAX_CARD_AMOUNT}.`
            );
            return;
        }

        if (!email.trim()) {
            setError('Udfyld din email-adresse korrekt!');
            return;
        }

        if (!username.trim()) {
            setError('Udfyld dit navn korrekt!');
            return;
        }

        // Create card claim

        try {
            setIsSubmitting(true);
            const cardClaim = await claimCards(
                Number(gameId),
                Number(cardAmount),
                username,
                email
            );
            setCardClaim(cardClaim);
        } catch (err) {
            if (err instanceof APIError && err.isCustomMessage) {
                setError(err.errorData!.message);
            } else {
                setError('Der opstod en fejl ved pladegenereringen.');
            }
        }

        setIsSubmitting(false);
    };

    const isControlsDisabled: boolean = isSubmitting || !!cardClaim;

    if (game && !isPhysicalGame && cardAmount === '--') {
        setCardAmount(`${MAX_CARD_AMOUNT}`);
    }

    if (isPhysicalGame && cardAmount === '--') {
        setCardAmount('');
    }

    return (
        <div className="page page-home">
            <div className="page__header">
                <h1>
                    Bingo Generator{' '}
                    <small style={{ fontSize: '0.4em' }}>
                        {' '}
                        - &copy; Asger Nohns 2020
                    </small>
                </h1>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <Card
                        title="Generer bankoplader"
                        subtitle={`Få dine bankoplader til spillet "${
                            game?.name ?? 'Indlæser...'
                        }"`}
                    >
                        <form onSubmit={handleCardClaim}>
                            <div className="form-group">
                                <label>
                                    Antal plader{' '}
                                    {!isPhysicalGame
                                        ? `(højst ${MAX_CARD_AMOUNT})`
                                        : ''}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={isControlsDisabled}
                                    value={cardAmount}
                                    onChange={(e) =>
                                        setCardAmount(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Din email-adresse</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="F.eks. anders@gmail.com"
                                    disabled={isControlsDisabled}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Dit navn</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="F.eks. Marianne Møller"
                                    disabled={isControlsDisabled}
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </div>
                            {error && (
                                <p className="form__error">
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                    />{' '}
                                    {error}
                                </p>
                            )}
                            <ModalStatus>
                                {isSubmitting && (
                                    <p
                                        style={{
                                            fontStyle: 'italic',
                                            color: '#777',
                                        }}
                                    >
                                        Genererer...
                                    </p>
                                )}
                            </ModalStatus>
                            <ModalButtons>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isControlsDisabled}
                                >
                                    <FontAwesomeIcon icon={faDice} /> Generer
                                    mine plader
                                </button>
                            </ModalButtons>
                        </form>
                    </Card>
                </div>
                <div className="col-md-7">
                    {cardClaim && (
                        <Card
                            title="Dine bankoplader er klar!"
                            subtitle="De genererede bankoplader skulle nu ligge klar i din UCRS indbakke."
                        >
                            <ol>
                                <li>Åbn din UCRS mail</li>
                                <li>
                                    Åbn mailen fra{' '}
                                    <strong>Bingo Generator</strong>
                                </li>
                                <li>
                                    Hent den vedhæftede pdf-fil med
                                    bankopladerne
                                </li>
                                <li>Print pladerne ud</li>
                                <li>God fornøjelse!</li>
                            </ol>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}
