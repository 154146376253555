import { API_HOST } from '../xstate/config.constants';
import { Game } from '../models/game.model';
import { GameRound } from '../models/game-round.model';
import { GameNumber } from '../models/game-number.model';
import { CardWinnerData } from '../models/card-winner-data.model';
import { CardClaim } from '../models/card-claim.model';
import { NestErrorData } from './interfaces/nest-error-data.interface';
import { GameType } from '../types/game-type.enum';

const composeFetchJsonBody = (payload: any) => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
});

export const createGame = (name: string, gameType: GameType) => {
    return fetch(`${API_HOST}/games`, {
        method: 'POST',
        ...composeFetchJsonBody({ name, gameType }),
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<Game>((res) => res.data);
};

export const deleteGame = (gameId: number) => {
    return fetch(`${API_HOST}/games/${gameId}`, {
        method: 'DELETE',
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<{ success: boolean }>((res) => res.data);
};

export const claimCards = (
    gameId: number,
    amount: number,
    username: string,
    email: string
) => {
    return fetch(`${API_HOST}/card-claims/${gameId}`, {
        method: 'POST',
        ...composeFetchJsonBody({
            amount,
            username,
            email,
        }),
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<CardClaim>((res) => res.data);
};

export const clearDeck = (gameId: number) => {
    return fetch(`${API_HOST}/game-rounds/${gameId}`, {
        method: 'POST',
        ...composeFetchJsonBody({}),
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<GameRound>((res) => res.data);
};

export const registerNumber = (gameId: number, number: number) => {
    return fetch(`${API_HOST}/game-numbers/${gameId}`, {
        method: 'POST',
        ...composeFetchJsonBody({ number }),
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<GameNumber>((res) => res.data);
};

export const checkWinner = (gameId: number, cardNumber: number) => {
    return fetch(`${API_HOST}/cards/winner/${gameId}/${cardNumber}`, {
        method: 'GET',
    })
        .then(throwHttpError)
        .then((res) => res.json())
        .then<CardWinnerData>((res) => res.data);
};

// Error handling middleware
export const throwHttpError = async (res: Response) => {
    if (!res.ok) {
        const error = new APIError(res);
        await error.setErrorData();
        throw error;
    }

    return res;
};

export class APIError extends Error {
    public errorData?: NestErrorData;
    public isCustomMessage?: boolean;

    constructor(public response: Response) {
        super(response.statusText);
    }

    public async setErrorData() {
        this.errorData = await this.response.json();
        this.isCustomMessage = !!this.errorData?.error;
    }
}
