import React, { ReactNode } from "react";

export default function({title, subtitle, children}: { title?: any, subtitle?: string | ReactNode, children?: any }) {
    return (
        <div className="card">
            <div className="card-body">
                {typeof title === 'string' ? <h4>{title}</h4> : title ?? ''}
                {typeof subtitle === 'string' ? <p>{subtitle}</p> : subtitle ?? ''}
                {children}
            </div>
        </div>
    )
}