import React, { ReactNode } from "react";

export interface ModalButtonsProps {
    children: ReactNode;
}

export default function ({ children }: ModalButtonsProps) {
    return (
        <div className="nohns-modal__buttons">{children}</div>
        
    );
}
