import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { cardClaimMachine } from "../../xstate/card-claims/card-claim.machine";
import { useParams } from "react-router-dom";
import Card from "../../components/Card";
import { gameMachine } from "../../xstate/games/game.machine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { API_HOST } from "../../xstate/config.constants";

export default function () {
    const [currentCardClaim, cardClaimSend] = useMachine(cardClaimMachine);
    const [currentGame, gameSend] = useMachine(gameMachine);
    const { gameId, cardClaimId } = useParams();

    // Fetch card claim
    useEffect(() => {
        cardClaimSend({
            type: "FETCH",
            gameId: Number(gameId),
            cardClaimId: Number(cardClaimId),
        });
        gameSend({ type: "FETCH", gameId: Number(gameId) });
    }, [gameId, cardClaimId, cardClaimSend, gameSend]);

    console.log(currentCardClaim.value);

    const formatDateTime = (time: string | number) => {
        const date = new Date(time);
        const dateString = date.toLocaleDateString().replace(/\./g, '/');
        const timeString = date.toLocaleTimeString().replace(/\./g, ':');

        return `${dateString} kl. ${timeString}`;
    }

    return (
        <div className="page page-home">
            <div className="page__header">
                <h1>
                    Bingo Generator{" "}
                    <small style={{ fontSize: "0.4em" }}>
                        {" "}
                        - &copy; Asger Nohns 2020
                    </small>
                </h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Card title="Dine bankoplader" subtitle="Information">
                        <p>
                            <strong>Spil: </strong>
                            {currentGame.context.data?.name ??
                                "Indlæser..."}
                        </p>
                        <p>
                            <strong>Tilhørende: </strong>
                            {currentCardClaim.context.data?.username ??
                                "Indlæser..."}
                        </p>
                        <p>
                            <strong>Oprettet den: </strong>
                            {currentCardClaim.context.data?.createdAt ? formatDateTime(currentCardClaim.context.data!.createdAt)
                                : "Indlæser..."}
                        </p>
                    </Card>
                </div>
                <div className="col-md-8">
                    <Card title="Hent bankoplader" subtitle="Tryk på knappen herunder for at se dine bankoplader">
                        <a target="blank" href={`${API_HOST}/card-claims/pdf/${cardClaimId}`} className="btn btn-primary">
                            <FontAwesomeIcon icon={faDownload} /> Hent bankoplader
                        </a>
                    </Card>
                </div>
            </div>
        </div>
    );
}
