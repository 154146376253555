import React, { useCallback, CSSProperties, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { State } from "xstate";
import {
    GameMachineContext,
    GameMachineEvent,
} from "../xstate/games/game.schema";
import ConfirmClearNumbersModal from "./modals/ConfirmClearNumbersModal";

import "./drawn-numbers.scss";
import Card from "./Card";
import CardMenu from "./CardMenu";
import { clearDeck } from "../api";

export interface DrawnNumbersCardProps {
    currentGame: State<GameMachineContext, GameMachineEvent, any, any>;
    sendGame: (event: GameMachineEvent, payload?: any) => any;
}

export default function ({ currentGame, sendGame }: DrawnNumbersCardProps) {
    const [showConfirmClearDialog, setShowConfirmClearDialog] = useState(false);

    const drawnStyles: CSSProperties = {
        borderColor: "#fda085",
        backgroundColor: "#fda085",
        color: "white",
        fontWeight: "bold",
    };

    const generateDrawnNumbers = useCallback(
        () =>
            [0, 10, 20, 30, 40, 50, 60, 70, 80].map((n1) => {
                const numbers =
                    n1 === 80
                        ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                const drawnNumberRow = numbers.map((n2) => {
                    const number = n1 + n2;

                    const isDrawn = currentGame.context.activeDrawnNumbers?.includes(
                        number
                    );

                    return (
                        <td key={number}>
                            <div
                                className="drawn-numbers__number"
                                style={isDrawn ? drawnStyles : {}}
                            >
                                {number}
                            </div>
                        </td>
                    );
                });

                return <tr key={n1}>{drawnNumberRow}</tr>;
            }),
        [drawnStyles, currentGame]
    );

    const handleConfirmClear = async () => {
        setShowConfirmClearDialog(false);

        await clearDeck(currentGame.context.gameId!);
        sendGame({ type: "CLEAR_DECK" });
    };

    return (
        <Card title="Udtrukkede numre" subtitle={`${currentGame.context.activeDrawnNumbers?.length ?? 0} / 90 numre udtrukket.`}>
            <CardMenu>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowConfirmClearDialog(true)}
                >
                    <FontAwesomeIcon icon={faTrash} /> Ryd
                </button>
            </CardMenu>
            <ConfirmClearNumbersModal
                isOpen={showConfirmClearDialog}
                onClose={() => setShowConfirmClearDialog(false)}
                onConfirm={handleConfirmClear}
            />
            <table className="bingo-dashboard__drawn-numbers__table">
                <tbody>{generateDrawnNumbers()}</tbody>
            </table>
        </Card>
    );
}
