import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import './App.scss';
import Game from './pages/Game';
import Claim from './pages/Claim';
import NotFound from './pages/NotFound';
import ClaimView from './pages/Claim/ClaimView';

function App() {
    //deleteGame(9).then(console.log);

    return (
        <div className="app">
            <div className="container">
                <Router>
                    <div>
                        <Switch>
                            <Route path="/game/:gameId">
                                <Game />
                            </Route>
                            <Route path="/claim/:gameId/:cardClaimId">
                                <ClaimView />
                            </Route>
                            <Route path="/claim/:gameId">
                                <Claim />
                            </Route>
                            <Route path="/dashboard">
                                <Home />
                            </Route>
                            <Route path="/">
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </div>
        </div>
    );
}

export default App;
