import React, { ReactNode } from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ({
    title,
    titleProps,
    prefix,
    prefixProps,
    faIcon,
    link,
}: {
    title: string | ReactNode;
    titleProps?: any;
    prefix?: string | ReactNode;
    prefixProps?: any;
    link?: string;
    faIcon: IconDefinition;
}) {
    if (!link) {
        link = "";
    }

    titleProps = titleProps ?? {};
    prefixProps = prefixProps ?? {};

    const itemContent = (
        <>
            <div className="list__item__icon">
                <FontAwesomeIcon icon={faIcon} />
            </div>
            {prefix && <div className="list__item__prefix" {...prefixProps}>{prefix}</div>}
            <div className="list__item__title" {...titleProps}>{title}</div>
        </>
    );

    return (
        <li className="list__item">
            {link ? (
                <Link to={link!} className="list__item__link">{itemContent}</Link>
            ) : <div className="list__item__link">{itemContent}</div>}
        </li>
    );
}
