import { Machine, MachineConfig, MachineOptions, assign, DoneInvokeEvent } from 'xstate';
import { GamesMachineContext, GamesMachineStateSchema, GamesMachineEvent, AddGameEvent } from './games.schema';
import { API_HOST } from '../config.constants';
import { Game } from '../../models/game.model';

const configuration: MachineConfig<GamesMachineContext, GamesMachineStateSchema, GamesMachineEvent> = {
    id: 'games',
    initial: 'idle',
    context: {
        data: [],
    },
    states: {
        idle: {
            on: {
                '': {
                    target: 'fetching',   
                }
            }
        },
        fetching: {
            invoke: {
                id: 'gamesFetch',
                src: 'gamesFetcher',
                onDone: {
                    target: 'complete',
                    actions: 'setData',
                },
                onError: {
                    target: 'failed',
                    actions: 'setError',
                },
            },
        },
        complete: {
            on: {
                ADD_GAME: {
                    actions: 'addGame'
                }
            }
        },
        failed: {
            on: {
                RETRY_FETCH: 'fetching',
            }
        }
    }
}

const options: MachineOptions<GamesMachineContext, GamesMachineEvent> = {
    services: {
        gamesFetcher: () => gamesFetcherService(),
    },
    guards: {},
    actions: {
        setData: assign<GamesMachineContext, any>({
            data: (_ctx: any, event: DoneInvokeEvent<Game[]>) => { console.log(event); return event.data}
        }),
        setError: assign<GamesMachineContext, any>({ 
            error: (_ctx: any, event: any) => event.data 
        }),

        addGame: assign<GamesMachineContext, any>({
            data: ({ data }: GamesMachineContext, { game }: AddGameEvent) => [...data, game]
        }),
    },
    activities: {},
    delays: {},
}

const gamesFetcherService = async () => {
    return fetch(`${API_HOST}/games`)
        .then(res => res.json())
        .then<Game[]>(json => json['data']);
}

export const gamesMachine = Machine(configuration, options);