import React, { useState, ChangeEvent, FormEvent } from "react";
import Card from "./Card";
import ModalButtons from "./modals/ModalButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPencilAlt,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { registerNumber } from "../api";
import ModalStatus from "./modals/ModalStatus";
import { useFocus } from "../hooks/useFocus.hook";

export interface RegisterNumberCardProps {
    gameId: number;
    onRegister: (number: number) => any;
    onRegistered: () => any;
    drawnNumbers?: number[];
}

export default function ({
    gameId,
    onRegister,
    onRegistered,
    drawnNumbers,
}: RegisterNumberCardProps) {
    const [number, setNumber] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [registerInputRef, setRegisterInputFocus] = useFocus();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const registeredNumber = Number(number);
        if (!registeredNumber) {
            return;
        }

        if (registeredNumber < 1 || registeredNumber > 90) {
            setError("Numre skal være mellem 1 og 90.");
            return;
        }

        if (drawnNumbers?.includes(registeredNumber)) {
            setError("Dette nummer er allerede registreret.");
            return;
        }

        onRegister(registeredNumber);
        setIsSubmitting(true);

        // Add number
        try {
            await registerNumber(gameId, registeredNumber);
            setNumber("");
            onRegistered();
        } catch (err) {
            setError("Nummeret kunne ikke registreres :(");
        }

        setRegisterInputFocus();
        setIsSubmitting(false);
    };

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (
            (!Number(value) && value.trim() !== "") ||
            (Number(value) % 1 !== 0 && value.trim() !== "")
        ) {
            setError("Indtast kun gyldige tal");
            setNumber(value);
            return;
        }

        setNumber(value);
        setError(null);
    };

    return (
        <Card
            title="Registrer nummer"
            subtitle="Indtast det udtrukkede nummer nedenfor."
        >
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Fx. 1, 2 eller 46 (1-90)"
                        value={number}
                        onChange={handleNumberChange}
                        ref={registerInputRef}
                    />
                </div>

                {error && (
                    <p className="form__error">
                        <FontAwesomeIcon icon={faExclamationCircle} /> {error}
                    </p>
                )}

                {isSubmitting && (
                    <ModalStatus>
                        <p style={{ fontStyle: "italic" }}>Registerer...</p>
                    </ModalStatus>
                )}

                <ModalButtons>
                    <button type="submit" className="btn btn-primary">
                        <FontAwesomeIcon icon={faPencilAlt} /> Registrer
                    </button>
                </ModalButtons>
            </form>
        </Card>
    );
}
