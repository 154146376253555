import { Machine, MachineConfig, MachineOptions, assign, DoneInvokeEvent } from 'xstate';
import { API_HOST } from '../config.constants';
import { Game } from '../../models/game.model';
import { GameMachineContext, GameMachineStateSchema, GameMachineEvent, FetchEvent, AddActiveDrawnNumber } from './game.schema';

const configuration: MachineConfig<GameMachineContext, GameMachineStateSchema, GameMachineEvent> = {
    id: 'games',
    initial: 'idle',
    states: {
        idle: {
            on: {
                FETCH: {
                    target: 'fetching',
                    actions: 'setGameId',   
                }
            }
        },
        fetching: {
            invoke: {
                id: 'gameFetch',
                src: 'gameFetcher',
                onDone: {
                    target: 'complete',
                    actions: ['setData', 'setActiveRound', 'setActiveDrawnNumbers'],
                },
                onError: {
                    target: 'failed',
                    actions: 'setError',
                },
            },
        },
        complete: {
            on: {
                RETRY_FETCH: 'fetching',
                CLEAR_DECK: {
                    target: 'fetching',
                    actions: 'clearDeck',
                },
                ADD_ACTIVE_DRAWN_NUMBER: {
                    actions: 'addActiveDrawnNumber',
                }
            }
        },
        failed: {
            on: {
                RETRY_FETCH: 'fetching',
            }
        }
    }
}

const options: MachineOptions<GameMachineContext, GameMachineEvent> = {
    services: {
        gameFetcher: (ctx: GameMachineContext) => gameFetcherService(ctx.gameId!),
    },
    guards: {},
    actions: {
        setGameId: assign<GameMachineContext, any>({
            gameId: (_ctx: any, event: FetchEvent) => event.gameId
        }),
        setData: assign<GameMachineContext, any>({
            data: (_ctx: any, event: DoneInvokeEvent<Game>) => event.data,
        }),
        setActiveRound: assign<GameMachineContext, any>({
            activeRound: (ctx: GameMachineContext, _event: any) => ctx.data?.gameRounds![0],
        }),
        setActiveDrawnNumbers: assign<GameMachineContext, any>({
            activeDrawnNumbers: (ctx: GameMachineContext, _event: any) => ctx.activeRound?.gameNumbers?.map(({ number }) => number),
        }),


        addActiveDrawnNumber: assign<GameMachineContext, any>({
            activeDrawnNumbers: (ctx: GameMachineContext, { number }: AddActiveDrawnNumber) => [...ctx.activeDrawnNumbers!, number]
        }),
        clearDeck: assign<GameMachineContext, any>({
            activeDrawnNumbers: []
        }),
        

        setError: assign<GameMachineContext, any>({ 
            error: (_ctx: any, event: any) => event.data 
        }),
    },
    activities: {},
    delays: {},
}

const gameFetcherService = async (gameId: number) => {
    return fetch(`${API_HOST}/games/${gameId}`)
        .then(res => res.json())
        .then<Game>(json => json.data);
}

export const gameMachine = Machine(configuration, options);