import { Modal } from "./Modal";
import React, { useCallback } from "react";
import { CardWinnerData } from "../../models/card-winner-data.model";
import ModalButtons from "./ModalButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export interface CheckWinnerModalProps {
    winnerData?: CardWinnerData | null;
    isOpen: boolean;
    onClose: () => any;
}

export default function ({
    winnerData,
    isOpen,
    onClose,
}: CheckWinnerModalProps) {
    const renderCardRows = useCallback(() => {
        if (!winnerData) {
            return <p>No data present</p>;
        }

        const rows = winnerData.cardRows;
        return rows.map((row, i) => {
            const renderedRow = row.map((number, j) => {
                const isBingo = winnerData.rows[i];
                const isDrawn = winnerData.rowsMap[i][j] === 1;

                const backgroundColor = isBingo
                    ? "#fda085"
                    : isDrawn
                    ? "#ffd6cb"
                    : "transparent";
                const fontWeight = isBingo ? "bold" : "normal";

                return (
                    <td key={i + "_" + j}>
                        <div
                            style={{ backgroundColor, fontWeight }}
                            className="check-winner-modal__number"
                        >
                            {number !== 0 ? number : ""}
                        </div>
                    </td>
                );
            });

            return <tr key={i}>{renderedRow}</tr>;
        });
    }, [winnerData]);

    return (
        <Modal
            title={winnerData?.statement ?? "Fejl"}
            onClose={onClose}
            isOpen={isOpen}
        >
            {!winnerData && <p>Ingen vinder information tilgængelig :(</p>}
            {winnerData?.cardClaim?.username && (
                <p>
                    <strong>Pladeejer: </strong>
                    {winnerData!.cardClaim!.username}
                </p>
            )}
            {winnerData && (
                <table className="check-winner-modal__table">
                    <tbody>{renderCardRows()}</tbody>
                </table>
            )}
            <br/>
            <ModalButtons>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onClose()}
                >
                    <FontAwesomeIcon icon={faCheck} /> OK
                </button>
            </ModalButtons>
        </Modal>
    );
}
